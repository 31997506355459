import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';
import { createAddEmailTemplate } from '../actions/email';
import { BASE_ZINDEX } from '../popup-factory';

import LabelledInput from './LabelledInput';
import RichTextEditor from './helpers/RichTextEditor';

class AddTemplatePopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email_template_title: '',
      email_template_text: '',
    };
    this.onChange = this.onChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }

  onChange(name) {
    return value => {
      this.setState({ [name]: value });
    };
  }

  handleAdd(e) {
    this.props.onAddTemplate(this.state.email_template_title, this.state.email_template_text.toString('html'));
    this.props.onClosePopup();
  }

  render() {
    const { onClosePopup, index } = this.props;

    return (
      <div className="reveal" style={{display: 'block', height: 'auto', zIndex: BASE_ZINDEX + index}}
        aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Add Email Template</h3>
          </div>
        </div>
        <div className="row column popup-content">
          <LabelledInput label="Template Name" className="small-12 columns" value={this.state.email_template_title} onChange={this.onChange('email_template_title')} />
          <div className="small-12 columns">
            <RichTextEditor value={this.state.email_template_text} onChange={this.onChange('email_template_text')} />
          </div>
          <button className="button float-right"
            type="button" onClick={this.handleAdd}>Add</button>
        </div>
        <button className="close-button"
          aria-label="Close modal" type="button"
          onClick={e => onClosePopup()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onAddTemplate: (email_template_title, email_template_text) => dispatch(createAddEmailTemplate({ email_template_title, email_template_text }))
  };
};

const ConnectedAddTemplatePopup = connect(
  mapStateToProps, mapDispatchToProps
)(AddTemplatePopup);
export default ConnectedAddTemplatePopup;

